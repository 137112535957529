import Icon from './Icon';

const CHEVRON_RIGHT_CURVED = (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="m8.3747 3.7892c-.3932.44221-.35347 1.1194.08873 1.5126 2.4005 2.1345 3.5409 3.5877 4.9907 5.7235.3986.5872.3986 1.3621 0 1.9493-1.4498 2.1358-2.5902 3.589-4.9907 5.7235-.4422.3932-.48193 1.0704-.08873 1.5126s1.0704.4819 1.5126.0887c2.5597-2.276 3.813-3.8721 5.3398-6.1213.8918-1.3137.8918-3.0426 0-4.3563-1.5268-2.2492-2.7801-3.8453-5.3398-6.1213-.44221-.3932-1.1194-.35347-1.5126.08873z" />
  </svg>
);

export default function IcChevronRightCurved({className}) {
  return (
    <Icon className={className} label="ChevronRightCurved">
      {CHEVRON_RIGHT_CURVED}
    </Icon>
  );
}

IcChevronRightCurved.propTypes = {
  className: Icon.propTypes.className,
};

IcChevronRightCurved.defaultProps = {
  className: '',
};
